@import   
  'scss/base',
  'scss/breadcrumbs',
  'scss/focus',
  'scss/fonts',
  'scss/footer',
  'scss/header',
  'scss/home',
  'scss/login-pages',
  'scss/page',
  'scss/pagination',
  'scss/payment-result',
  'scss/product-card',
  'scss/product-page',
  'scss/results-header',
  'scss/service-column',
  'scss/sidebar',
  'scss/snipcart',
  'scss/tables',
  'scss/user-area';


a[disabled], a[disabled]:hover {
   pointer-events: none;
   opacity: 0.4;
}
