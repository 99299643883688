.pre-header {
  color: var(--beige-light);
  font-size:.9rem;
  position: relative;  
}
@media screen and (max-width: 767px) {
  .pre-header {
    height: 60px;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0; */
    background-color: var(--grey-dark-warm);
    z-index: 19;
    box-shadow: 1px 1px 5px #282828;
  }
  .service-menu-mobile {
    height: 60px;
    align-items: center;
  }
  .menu-mobile .p-menubar {
    height: 60px;
  }
}
.header-container {
  background-color: var(--beige-dark);
  /* margin: 0.2rem 0.1rem 0.1rem 0.1rem ; */
  margin: 0.1rem 0.1rem;
  .p-menubar {
    @include styleclass('hidden md:flex');
  }
  .p-menubar .p-menubar-button{
    color: var(--text-color);
    &:hover {
      background: none;
    }
  }
  .p-megamenu {
    background-color: transparent;
    border:none;
    @include styleclass('px-3');
  }
  input {

    border-radius: 50px!important;
    padding-left: 20px;
    padding-right: 50px;
    border-color: var(--onyx);
  }
  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    width:2.5rem;
    margin-left:-40px;
    z-index: 15;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--beige-light);
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--beige-medium);
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: var(--focus-shadow);
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--text-color);
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: var(--text-color);
    font-weight: 600;
  }
  .p-megamenu .p-megamenu-panel {
    background: var(--beige-light);
    color: ar(--text-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-megamenu .p-megamenu-submenu-header {
    
    background: transparent;
    
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: var(--onyx);
    
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--beige-light);
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem .75rem;
  }

  /*zic addition*/
  .message {
	  background-color: var(--honey-bunny);
	  color: var(--text-color);
	  padding: 10px 15px;
	  text-align: center;
  }
}

.header-container, .menu-mobile {
  .p-menubar {
    background-color: transparent;
    border:none;
    @include styleclass('px-3');
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--beige-light);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--beige-medium);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: var(--focus-shadow);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--text-color);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: var(--text-color);
    font-weight: 600;
  }
  .p-menubar .p-submenu-list {
    background: var(--beige-light);
    color: var(--text-color);
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-top: 0.25rem;
  }
  .p-menubar .p-menubar-submenu-header {
    
    background: transparent;
    
  }
  .p-menubar .p-menuitem-link {
    padding: 0.5rem 1rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    margin-left: 0.25rem;
  }
  .p-menubar .p-menuitem-link .p-menuitem-text {
    line-height: 1.2;
    color: var(--text-color);
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    
    background: var(--beige-medium);
    .p-menuitem-text {
      color: var(--text-color);
    }
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: var(--onyx);
    
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: var(--beige-light);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem .75rem;
    border-radius: 50px;
  }
  .p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid var(--beige-dark);
    
  }

}
.p-menubar-button {
  width: auto!important;
  &:after {
        content: 'menu';
        display: block;
        margin-left: .5rem;
      }
}
.header-address {
  @include styleclass ('hidden md:block px-4 py-3')
}
.menu-mobile, .service-menu-mobile {
  @include styleclass ('md:hidden')
}
.menu-mobile {
  margin: 1px 0.1rem;
  position: relative;
  .p-menubar {
    padding: 0.5rem!important;
    background: transparent;    
    border: none;
    border-radius: 0px;
    justify-content: flex-end;
    .p-menubar-button {
      color: var(--beige-light);
      width: 40px !important;
      height: 40px;
      &:after {       
        display: none;       
      }   
      &:hover {
        background: var(--beige-light);
        color: var(--text-color);
      }  
    }
  }
}
.service-menu-mobile {
  position: absolute;
  bottom: 0;
  padding: 0.5rem .5rem 0.5rem 1.5rem;
  display: flex;
  width: calc( 100% - 3rem );
  justify-content: space-between;
  .logo-mobile {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; 
    font-weight: 600;
    font-size: 1rem;
  }
  .service-buttons-mobile {
    display: flex;
    justify-content: end;
    .service-buttons--button {
      position: relative;
      .p-badge {
        position: absolute;
        top:7px;
        right:4px;
        &.p-badge-dot {
            width: 0.75rem;
            min-width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            padding: 0;
        }
        &.p-badge-success{
          background-color: #7bba43;
        }
      }
    }
  }
  
  a {
    color: var(--beige-light);
    width: 2.5rem;
    height: 2.5rem;    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
    text-decoration: none;
    &:last-child {
      margin-right: 0;
    }
    
    &.text-link {
	    width: auto;
	    text-decoration: none;
    }
  }
}
.menu-mobile {
  .p-menu-separator {
    width:100%;
  }
  ul.p-menubar-root-list {
    //max-height: calc(100vh - 60px);
    //overflow: scroll;
  }
}
.search-bar {
  @include styleclass (
    'col-12 sm:col-9 md:col-8 lg:col-6 pl-4 pt-3 pb-3 md:pb-0 pr-4'
    );
}


.menu-promo {
  span {
    padding: 0 3px;
    background-color: var(--yellow);
  }
}













