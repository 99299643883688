.pagination-container {
    padding: 0 .1rem;
    margin-top: -.4rem;
}
.pagination.p-paginator {
    background: var(--beige-dark);
    color: #2e2e2e;
    border: none;
    border-width: 0;
    padding:  1rem;
    border-radius: 0px;
    .p-paginator-pages .p-paginator-page, {
        color:var(--text-color);
    }
    .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
        color:var(--text-color);
    }
    .p-paginator-pages .p-paginator-page.p-highlight {
        background-color: var(--beige-light);
        color:var(--text-color);
    }
    .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background-color: var(--onyx);
        color: var(--beige-light);
    }
    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background-color: var(--onyx);
        color: var(--beige-light);
    }
    .p-disabled, .p-component:disabled {
        opacity: 0.5;
    }
}


.p-paginator {
    background: var(--beige-dark);
    color: #2e2e2e;
    border: none;
    border-width: 0;
    padding:  1rem;
    margin: 1px 0.1rem;
    border-radius: 0px;
    .p-paginator-pages .p-paginator-page, {
        color:var(--text-color);
        background-color: transparent!important;
    }
    .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
        color:var(--text-color);
            background-color: transparent!important;
            text-decoration: none;
    }
    .p-paginator-pages .p-paginator-page.p-highlight {
        background-color: var(--beige-light)!important;
        color:var(--text-color);
    }
    .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background-color: var(--onyx)!important;
        color: var(--beige-light);
    }
    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background-color: var(--onyx)!important;
        color: var(--beige-light);
    }
    .p-disabled, .p-component:disabled {
        opacity: 0.5;
    }
}
.p-paginator .p-paginator-current {
    color: var(--text-color);
    opacity: 0.5;
}
