.home-group-label {
  /* background-color: var(--beige-light) */

}
.gift-maker-launch, .catalog-launch {
  margin: 1px .1rem;
  h2 {color: #fff;}
  color: #fff;
  @include styleclass('p-4');
  //background-image: url("../../public/img/home/gift-bkg.webp");
  background-image: url("../../public/img/home/giftmaker.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(../../public/img/circle-neutral.svg);  */
  a {
    cursor: pointer;
    text-decoration: none;
    span {
      cursor: pointer;
    }
  }
  p {

    font-weight: 600;

  
  }
}
.catalog-launch {
  background-image: url("../../public/img/home/back-catalog.webp");
}
.banner-xmas {
  margin: 1px .1rem;
  h2 {color: #fff;}
  color: #fff;
  @include styleclass('p-4');
  background-image: url("../../public/img/home/banner-xmas.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  
  a {
    cursor: pointer;
    text-decoration: none;
    span {
      cursor: pointer;
    }
  }
}

.banner-champagne {
  margin: 1px .1rem;
  h2 {
    color: #fff;
    font-size: 2rem;
  }
  color: #fff;
  @include styleclass('p-4');
  background-image: url("../../public/img/home/banner-champagne.webp");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  
  a {
    cursor: pointer;
    text-decoration: none;
    span {
      cursor: pointer;
    }
  }
}

.banner-button {
  background-color: var(--honey-bunny);
  padding: 10px 20px;
  color: var(--text-color);
  border-radius: 50px;
  margin-bottom: .5rem;
  display: inline-block;
  font-weight: 600;
}
.double-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2px 0;
  @include for-phone-only {
    grid-template-columns: 1fr;
  }
  @include for-tablet-portrait-up {
    grid-template-columns: 1fr;
  }
  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 1fr;
  }

}