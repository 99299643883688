table {
    border-collapse: collapse;
    
    table-layout: fixed;
}
table thead > tr > th {
    text-align: left;
    padding: 1rem .5rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #343a40;
    background: var(--beige-medium-dark);
    transition: box-shadow 0.2s;
}
table tbody > tr > td {
    padding: .5rem .5rem;
}
