.product-page--container {
  margin: 0 .1rem!important;
  background-color: var(--beige-light);
  min-height: 600px;
  .product-page--content {
    background-color: var(--beige-light);

    .product-page--left-col {
      @include styleclass ('col col-12 sm:col-4')
    }
    .product-page--right-col {
      @include styleclass ('col col-12 sm:col-8 p-4 sm:p-6')
    }

    h1 {
      margin: .5rem 0 ;
      span{

      }
    }
    .product-price {
      font-size: 1.5rem;
      margin-top: 1.5rem;
      .discount-perc {
        display: block;
        font-size: 1rem;
        margin-bottom: .25rem;;        
        span {
          padding: 2px 5px;
          border-radius: 0px;
          background-color: var(--yellow);
        }
      }
      .discount-price {
        display: inline-block;
        margin-right: .5rem;
      }
      .original-price {
        text-decoration-line: line-through;
        font-size: 1.125rem;
        display: inline-block;
        opacity: .7;
      }
      
      
    }
    .product-quantity-selector {
      margin-top: 1rem;
      background-color: #d6b786;
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
      border-radius: 50px;
      .p-inputnumber {
        margin-right: 1rem;
        background: transparent;
        border-radius: 50px;
        display: flex;
        align-items: center;
        //border: 1px solid var(--onyx);
        .p-inputnumber-input {
            width: 50px;
            margin: 0 0.4rem;
            border-radius: 50px;
            background: white;
            padding: 10px;
        }
      }
    }
    .product-card__quantity__button {
      width: 40px !important;
      height: 40px !important;
    }
    .p-inputnumber-buttons-horizontal .p-inputnumber-input {
      width: 40px;
      margin: 0 .4rem;
    }
    .p-inputtext {
        font-size: 1rem;
        background: transparent;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0;
        border: none;
    }
  }
  .product-page--image-container {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    @include styleclass('p-4');
  }
  .product-description {
    margin-top: 2rem;
  }
  .product-first-facts {
    .divider {
      border-left: 1px solid var(--beige-dark);
      height: 15px;
      margin: 0 .5rem;
    } 
  }
  .buy-button{
     padding: 0.6rem 1rem; 
  }
  .product-facts{
    margin-top: 2rem;
    column-count: 1;
      dl {
      display: flex;
      flex-direction: column;
      /*justify-content: space-between;*/
      align-items:start;
      margin-block-start: 0;
      margin-block-end: 0;
      border-bottom: 1px solid #ccc;
      padding: 0 0 0.5rem 0;
      &:first-child {
        padding-top: 0;
      }
      dt{
          text-transform: uppercase;
          font-size: 0.75rem;
          font-weight: 700;
          line-height: 21px;
          font-family: "Assistant";
          letter-spacing: 1px;
          opacity: .5;
      }
      dd{
        margin-inline-start: 0px;
        line-height: 21px;
        
      }
    }
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  

}
.product-first-facts {
  display: flex;
  align-items:center;
}
.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .6rem;
    letter-spacing: .3px;
   

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
      display: none;
    }
    &.status-lowstock {
      background: var(--beige-medium-dark);
      color: var(--grey-dark-warm);
    }
    &.status-nostock {
      background: #ba6868;
      color: white;
    }
  }
.wine-color-badge {
    width: 15px;
    height: 15px;
    /* border-radius: 50px; */
    background-repeat: no-repeat;
    background-color: transparent;
    display: inline-block;
    &.circle-neutral {
      /* background: #e2e2e2; */
      background-image: url(../../public/img/glass-neutral.svg);
      background-repeat: none;
      background-position: center center;
      }    
    &.circle-orange-friz {
      /* background: #fbbc70; */
      background-image: url(../../public/img/glass-orange.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-orange {
      /* background: #fbbc70; */
      background-image: url(../../public/img/glass-orange.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-porcino {
      /* background: #cca186; */
      background-image: url(../../public/img/glass-porcino.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-red-friz {
      /* background: #da7171; */
      background-image: url(../../public/img/glass-red.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-red {
      /* background: #da7171; */
      background-image: url(../../public/img/glass-red.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-rose {
      /* background: #f4b7c5; */
      background-image: url(../../public/img/glass-rose.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-rose-friz {
     /*  background: #f4b7c5; */
      background-image: url(../../public/img/glass-rose.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-white-friz {
      /* background: #f6e38a; */
      background-image: url(../../public/img/glass-white.svg);
      background-repeat: none;
      background-position: center center;
      }
    &.circle-white {
      /* background: #f6e38a; */
      background-image: url(../../public/img/glass-white.svg);
      background-repeat: none;
      background-position: center center;
      }    
}
.product-detail-loader {
  background-color: var(--beige-light);
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
