.page-content-container {
  margin: 0 .1rem!important;
  background-color: var(--beige-light);
  .page-content {
    background-color: var(--beige-light);
    @include styleclass('pt-4 px-4 md:px-8 pb-5');
  }
}
.payments-list {
  margin: 0;
  padding:0;
  li {display: inline-block;}

}
.orders-page-list {
  line-height: 1.5;
}