.snipcart * {
    font-family:'Sen';
    font-size: 16px;
    white-space: normal;
    line-height: 1.1;
}
.snipcart-cart-header {
}

.snipcart__font--subtitle {
}
.snipcart-cart-header__option-title {
    font-weight: 600;
    margin: 1rem 0 .25rem 0;
    font-size: 14px;
    text-transform: uppercase;
}
.snipcart-summary-fees__notice.snipcart__font--regular {
    font-size: 14px;
    margin-bottom: .75rem;
}
.snipcart-cart-header__option.custom-sign-in {
    margin-bottom: 0;
        margin-right: 1rem;
}
a.snipcart-cart-header__option {
    color: var(--link-color);
    text-decoration: underline;
    justify-content: start;
    font-size: 14px;
    margin-bottom: .5rem;
}
.snipcart-modal__container {
    background-color: var(--beige-light);
}
.snipcart-item-line {
    box-shadow: none;
}
.snipcart-modal, .snipcart-cart__content, .snipcart-cart-header, .snipcart-featured-payment-methods__title {
    background-color: var(--beige-light);
}
.snipcart-cart-header .snipcart-modal__close-icon, .snipcart-cart-header__icon {
    color: var(--link-color);
}
.snipcart-button-icon.is-secondary {
    background-color:var(--onyx);
    color: #fff;
    &:hover {
        background-color:var(--black);
        color: #fff;
    }
}
.snipcart__icon--left, .snipcart-billing-completed .snipcart-checkout-step__icon, .snipcart-billing-completed__title .snipcart__icon--left {
    color: var(--link-color);
}
.snipcart-shipping-completed .snipcart-checkout-step__icon, .snipcart-shipping-completed__title .snipcart__icon--left {
    color: var(--link-color);
}
.snipcart-order__title svg {
    color: var(--link-color);
}
.snipcart-order__invoice-number--highlight {
    color: var(--link-color);
}
.snipcart-order__details__content .snipcart-order__box, .snipcart-order__details .snipcart-order__box{
    margin-top: -2px;
    background-color: #fff;
}
.snipcart-base-button {    
    border-radius: 50px;
}
.snipcart-button-link {
    color: var(--link-color);
    font-size: 14px;
}
.snipcart-item-line__header {
   .snipcart-button-icon {
        width: 20px;
        height: 20px;
        .snipcart__icon {
            width: 20px;
            height: 20px;
        }
    } 
}
.snipcart-button-icon {
    width: 25px;
    height: 25px;
    .snipcart__icon {
        width: 25px;
        height: 25px;
    }
}
.snipcart-shipping-rates-list-item {
    border-radius: 50px;
    border-color: var(--onyx);
}
.snipcart-shipping-rates-list-item--highlight {
    border: 3px solid #0aa7f5;
     
}
.snipcart-checkout-step__icon {
    margin-top: 0;
}
.snipcart-shipping-rates-list-item {
    margin: 5px 0;
}
.snipcart-button-icon.is-danger:hover {
    box-shadow: none;
}
.snipcart-item-line__container {
    padding: 1rem;
    border: 1px solid var(--grey-dark-warm);
    margin-bottom:.5rem!important;
}
.snipcart-item-line__container {
    margin: 0 0 24px;
}
.snipcart-item-quantity__label {
    font-size: 12px;
    text-transform: uppercase;
}
.snipcart-item-quantity__quantity {
    height: auto;
    //border: 1px solid;
    //border-color: #989898;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    //border-radius: 50px;
    width: 100px;
     > span {
        background: #f7f4f0;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: inline-block;
        text-align: center;
        padding: 7px 0;
    }
}
.snipcart-button-primary {
    background-color:var(--onyx);
    &:hover {
        background-color:var(--black);
        color: #fff;
        box-shadow: none;
    }
}

.snipcart__box--badge {
    background-color:var(--beige-light);
    border: 1px solid var(--grey-dark-warm);
    font-size: 18px;
    font-weight: 600;
    color: var(--green-600);
    &.snipcart__box--badge-highlight {
        color: var(--text-color);
    }
}
.snipcart__box {
    background-color:var(--beige-light);
    border: 1px solid var(--grey-dark-warm);
    &.snipcart__box--gray {
        background-color:var(--beige-medium-dark);
    }
}
.snipcart__font--std, .snipcart-form__label, .snipcart-shipping-completed__information {
    font-size: 14px;
}
.snipcart-checkout-step__col {
    align-items: start;
}
.snipcart__box {
    .snipcart__box--badge {
        color: var(--beige-light);
        background-color: var(--onyx)!important;       
    }
}
.snipcart__box.snipcart__box--gray {
    .snipcart__box--badge {
        color: var(--text-color);
        background-color: var(--beige-light)!important;       
    }
}
.snipcart-billing-completed {
    .snipcart-checkout-step__icon {
        color: var(--text-color);
      
    }
   
}
.snipcart-shipping-completed {    
    .snipcart-checkout-step__icon {
        color: var(--text-color);        
    }
    
}
.snipcart-cart-summary {
   background-color:var(--beige-light); 
}
.snipcart-featured-payment-methods__link {
    font-size: 14px;
}

.snipcart-input__input, .snipcart-textbox {
    border: 1px solid var(--beige-dark);
}
.snipcart-payment-form {
    background-color: #fff;
    border: 1px solid var(--beige-dark);
}

.snipcart .snipcart-cart-summary-item {
    margin: 0;
    .snipcart-cart-summary-item__name.snipcart__font--slim {
        font-size: 14px!important;
    }
} 
.snipcart-cart__secondary-header {
    background-color: var(--onyx)!important;  
    color: #fff;

}
.snipcart-cart--edit .snipcart-cart__content {
    background-color:var(--beige-light); 
}
.snipcart-cart--edit .snipcart-item-list, .snipcart-item-line--cart-edit {
    background-color:var(--beige-light); 
}
.snipcart-item-line--cart-edit {
    border-color: var(--text-color);
    padding: 1rem 0;
}
.snipcart-item-line--cart-edit .snipcart-item-line__title {
    padding-left: 0;
}
.snipcart-button-secondary {
    color: var(--beige-light); 
    border-color: transparent;
    background-color: var(--onyx);
    &:hover {
        background-color: var(--black);
         color: var(--beige-light); 
        box-shadow: none;
    } 
}
.snipcart-payment-methods-list-item__button {

    border: 1px solid;
    border-color: var(--onyx);
    border-color: var(--onyx);
    padding: 16px;
    width: 100%;
    height: 64px;
    cursor: pointer;
    background: white;
    border-radius: 50px;
}
.snipcart-payment-methods-list-item__arrow {
    color: var(--onyx);
    .snipcart__icon {
        width:25px;
        height: 25px;
    }
}
.snipcart-empty-cart {
    color: var(--text-color);
}
.snipcart-payment-form {
    p {
        display: block;
    }
}
.gift-box-title {
    margin-top: 10px;
}
.gift-box-fieldset {
    label {
        font-weight: 600;
    }
    p {
        font-size: 14px;
        line-height: 1.2;
    }
}
.snipcart .snipcart-cart-summary-item .snipcart-cart-summary-item__name.snipcart__font--slim {
    font-size: 14px !important;
    line-height: 1.2;
}
.policy-wrapper {
    margin-bottom: 1.5rem;
    a {
        display: inline-block;
    }
}
.snipcart-checkbox+label:before {
   
    min-width: 20px;
   
}
.snipcart__box--header h1 {
    font-size: 21px;
}
.policy-wrapper {

    .snipcart-checkbox+label {
        
        align-items: flex-start;
       
    }
    .snipcart-checkbox:checked+label:after {
        
    }
}
.paypal-card-hint {
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2;
    strong {
        font-size: 14px;
        font-weight: 600;
        display: inline-block;

    }
}
@media (min-width: 1024px) {
    .snipcart-item-line--cart-edit {
    margin: 0 2rem; 
    }
}
.snipcart-summary-fees__discounts-icon {
    display: none;
}
