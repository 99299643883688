.footer-container {
  padding: 0 .1rem;
  margin-top: .15rem;
  color: var(--beige-light);
  a {
    color: var(--beige-light);
    text-decoration: none;
  }
  .footer-link {
    @include styleclass('block cursor-pointer line-height-2 mb-3');
  }
}