.breadcrumbs-container {
  margin: 0 .1rem .15rem .1rem!important;
  background-color: var(--beige-light);

  .breadcrumb-content {
    background-color: var(--beige-light);
    @include styleclass('pl-4');
    ul li .p-menuitem-link .p-menuitem-text {
      color: var(--text-color);
      font-size: .9rem;
    }
    li.p-breadcrumb-chevron {
      margin: 0 0.5rem 0 0.5rem;
      color: #495057;
      line-height: .5;

      &.pi-chevron-right:before {
          content: "\e901";
          font-size: .7rem;
      }
    }
  }
}