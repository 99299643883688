.login-wrapper {
 
    background-color: var(--beige-medium);
    background-image: url(../../public/img/r-pattern.svg);
    background-repeat: repeat;
    background-size: 200px;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: start;
    justify-content: center;
    padding-top:64px;

}
.form-wrapper {
      max-width: 350px;
      
      @include styleclass ('flex flex-column align-items-center justify-content-center form-wrapper p-4');
}
.back-to-home {
  font-size: 14px;
  @include styleclass ('cursor-pointer mt-3')
}
.p-password input {
  width:100%;
}

.p-error {
    margin-bottom: 0.5rem;
    display: block;
    margin-top: -2px;
}
.login-accordion {
    max-width: 261px;
    margin-bottom: 10px;
    .p-accordion-header {
        background-color: transparent;
        a {
            background-color: transparent;
        }
    }
    .pi {
        font-size: .8rem;
    }
     .p-accordion-header-link {
        background-color: transparent!important;
        border: none!important;
        padding: 0!important;
        font-size: .9rem;
        color: var(--link-color)!important;
    }
    .p-accordion-content {
        font-size: .9rem;
        
        background-color: transparent;
        border: none;
        padding: 0;
        p {
            line-height: 1.2!important;
        }
    }
}