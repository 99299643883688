
.service-column {
  a {
    text-decoration: none;
  }
  @include styleclass ('hidden md:flex');
  font-family: 'Assistant', Helvetica, Arial, sans-serif ;
  min-width:100px;
  max-width:100px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  .lang-switcher {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(var(--beige-dark), .6);
    button {
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      padding: .1rem .3rem;
      color: var(--beige-light);
      background-color: transparent;
      border-color:transparent;
      &:hover {
        background-color: transparent;
        border-color: var(--beige-light);
        color: var(--beige-light);
      }
      &:nth-child(1){margin-right:3px;}
      &.active-lang {
        background-color: var(--beige-light);
        border-color: var(--beige-light);
        color: var(--black-leather);
      }
    }
  }
  .lang-switcher-plain {
    padding-bottom: 15px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--beige-dark-alpha);
    color: var(--beige-light);
    a {
      display: flex;
      align-items: center;
      color: var(--beige-light);
      cursor: pointer;
      img {
        margin-right: 3px;
      }
    }
  }
  button {
    border: none;
    background-color: transparent;
  }
  .service-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    padding-top: 15px ;
    .service-buttons--button {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items:center;
      padding: 0 0 15px 0;
      min-width: 60px;
      cursor: pointer;
      img {width: 48px;}
      span {
        text-transform: uppercase;
        color: var(--beige-light);
        font-size: .85rem;
        margin-top: 5px;
        font-weight: 600;  
      }
      &.service-buttons--button-cart {
        .p-badge {
          top: 8px;
          right: 13px;
          border: 2px solid var(--beige-light);
          box-sizing: content-box;
          font-size: .85rem;
          padding: 0;
          border-radius: 50px;
          &.p-badge-success {
            background-color: #396543;
          }
          &.p-badge-danger {
            background-color: #813b3b;
          }
        }
      }
    }
  }
  .p-scrolltop {
    position: static;
    margin: auto;
  }
}