
.results-header {
  background-color: var(--beige-dark);
  /* margin: 0.2rem 0.1rem 0.1rem 0.1rem ; */
  margin: 1px 0.1rem;

  @include styleclass('pl-3 pr-3 pt-2 pb-2');
  h1 {
        margin: 0.5rem 0;
    font-weight:400;
    span {
      font-weight: 600;
    }
  }
  .p-multiselect .p-multiselect-label {
        padding: 0.5rem 0rem 0.5rem 0.75rem;
    
      }
  .select-filters {
    > div {
      margin-right: .75rem;
      border-radius: 50px;
      border: none;

    }
    .p-multiselect.selected {
      border: 1px solid #396543;
      background-color: #e3ece5;
    }
  }
  
  .selected-filters {
    button {
      margin-right: 6px;
    }
    .p-button.p-button-sm .p-button-icon {
    font-size: 0.8rem;
    }
    .p-button-outlined {
      background-color: transparent!important;
      color: var(--text-color);
    }
    .p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
      background-color: var(--onyx)!important;
      color: #fff;
      border: 1px solid;
    }
  }
  .p-divider.p-divider-horizontal {
    margin: .25rem .5rem;
    width: auto;
    
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px solid #b1aeab;
  }
}
.p-dataview .p-dataview-header {
  background-color: var(--beige-dark);
  margin: 1px 0.1rem;
  padding: 0.5rem 1.5rem;
    border:none;
}
.p-dataview .p-dataview-content {
  background-color: transparent;
}
.sorting-dropdown {
  border: none;
  background-color: #fff;
  border-radius: 50px;
  .p-dropdown-label.p-placeholder {
      color: var(--text-color);
      padding: .2rem 0 .3rem .75rem;
   
  }
  .p-dropdown-trigger {
      /* color: var(--text-color); */
      margin-top: 2px;
      .p-dropdown-trigger-icon {
        font-size: .8rem;
      }
  }
  
  .p-inputtext {
    padding: .3rem .5rem;
    font-size: 14px;

  }
}
.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: var(--focus-shadow);
}
.p-multiselect-panel {
    border-radius: 10px;
    margin-top: 0.5rem;
    background: var(--beige-light);
    
  }
  .p-multiselect-panel .p-multiselect-header  { 
      color: var(--text-color);
      background: var(--beige-medium);
      margin: 0;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: var(--text-color);
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: var(--beige-light);
    border-color: transparent;
    background: var(--onyx);
}
.p-dropdown-panel {
    background: var(--beige-light);
    color: var(--text-color);
    border: 0 none;
    border-radius: 10px;
    margin-top: .5rem;
    font-size: .9rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: var(--beige-medium);
    color: var(--text-color);

}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--text-color);
    background: var(--beige-medium);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: inset 0 0 0 0.15rem var(--focus-shadow);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--text-color);
    background: var(--beige-medium);
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--onyx);
    background: var(--onyx);
    border-radius: 0;
}
.no-results {
  background: var(--beige-medium);
  margin: 0 .1rem;
  @include styleclass('p-4');
  h2 {
    margin:0;
  }
}