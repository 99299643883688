.user-area-menu {
  .p-tabmenu-nav {
    background-color: transparent;
    border-color: var(--beige-dark);
    .p-tabmenuitem .p-menuitem-link {
      background-color: transparent;
      border-color: var(--beige-dark);
      color: var(--text-color);
    }
    .p-tabmenuitem.p-highlight .p-menuitem-link {
      background-color: transparent;
      border-color: var(--text-color);
      color: var(--text-color);
    }
    .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background-color: transparent;
      border-color: var(--text-color);
      color: var(--text-color);
    }
    .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: var(--focus-shadow-2);
    }
  }

}
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--text-color);
    background: #ffffff;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: var(--text-color);
}
.orders-list {
  .p-accordion-header .p-accordion-header-link {
    background: transparent;
    border: none;
    color: var(--text-color);
  }
  .p-accordion-content {
    border: none;
    color: var(--text-color);
  }
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: transparent;
    border-color: transparent;
    color: var(--text-color);
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: var(--beige-medium);
    border-color: transparent;
    color: var(--text-color);
  }
  .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: var(--beige-medium);
    border-color: transparent;
    color: var(--text-color);
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    border-bottom: 1px solid var(--beige-medium);
    padding: 5px;
  }
  .order-list-total {
    margin-top: 5px;
    padding: 5px;
    font-weight: 500;
  }
}