//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700;800&display=font-display:optional&subset=latin');
//@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=font-display:optional&subset=latin');

/* sen-regular - latin */
@font-face {
  font-family: 'Sen';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/sen-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../public/fonts/sen-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/sen-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/sen-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../public/fonts/sen-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/sen-v7-latin-regular.svg#Sen') format('svg'); /* Legacy iOS */
}
/* sen-700 - latin */
@font-face {
  font-family: 'Sen';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../public/fonts/sen-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../public/fonts/sen-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/sen-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/sen-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../public/fonts/sen-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/sen-v7-latin-700.svg#Sen') format('svg'); /* Legacy iOS */
}
/* assistant-600 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../public/fonts/assistant-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../public/fonts/assistant-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/assistant-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/assistant-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../public/fonts/assistant-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/assistant-v18-latin-600.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-700 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../public/fonts/assistant-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../public/fonts/assistant-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/fonts/assistant-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/fonts/assistant-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../public/fonts/assistant-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/fonts/assistant-v18-latin-700.svg#Assistant') format('svg'); /* Legacy iOS */
}