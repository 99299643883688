.cards-container {
  background-color: var(--grey-dark-warm);
  /* padding: 0.5rem!important; */
}
.react-card-flip {
    position: relative;
    height: 100%;
    &:hover, &.flipped {
      z-index: 20!important;
    }
    
}
.react-card-flipper {    
    height: 100%;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -moz-transform: perspective(5500px);
    transform: perspective(5500px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    &:hover {
      z-index: 20;
    }
}
.product-card-regular {
  padding: .1rem!important;
}
.product-card {
  background-color: var(--beige-light);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  //border: 1px solid var(--black-leather);
  @include styleclass('text-gray-900 transition-all transition-duration-200');

  .product-grid-item-top {
    position: absolute;
    top: 12px;
    left: 12px;
    .discount-perc {
      span {
        font-weight: 600;
        padding: 2px 5px;
        border-radius: 0px;
        background-color: var(--yellow);
      }
    }
  }
  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .6rem;
    letter-spacing: .3px;
    

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
      display: none;
    }
    &.status-lowstock {
      background: var(--beige-medium-dark);
      color: var(--grey-dark-warm);
    }
    &.status-nostock {
      background: #ba6868;
      color: white;
    }
  }
}
.product-card:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18);
}
.product-card.-retro {
  background-color: var(--beige-medium);
  @include styleclass('shadow-6 font-bold text-gray-900');
  .product-card__bottle {
    position: absolute;
    top: 0.5rem;
    width: 100%;
    opacity: .1;
    img {}
  }
}
.product-card__price  {
  background-color: var(--beige-medium);
  height:60px;
  display: flex;
  align-items: center;
  @include styleclass('flex flex-wrap justify-content-between align-content-center');
  .original-price {
    display: block;
    text-decoration-line: line-through;
    font-weight: 400;
  }
  .discount-price {
    display: block;
    font-weight: 600;
    font-size: 1.25rem;
  }
}
.product-card__continue  {
  height:60px;
}
.product-card__confirm { 
  height:30px;
}
.product-card__confirm, .product-card__continue  {
  background-color: var(--beige-medium);
  
  display: flex;
  align-items: center;
  @include styleclass('flex flex-wrap justify-content-center align-content-center');
}
.product-card.-retro .product-card__price {
  background-color: var(--beige-light);
}
.product-card__image {  
  position:relative;
  @include styleclass('pt-2 pb-2');
}
.product-card__bottle img {
  z-index:2;
  position: relative;
  height: 250px;
}
.product-card__circle {
  width:100%;
  height:100%;  

  position: absolute;
  top:0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .product-card__circle-container {
    
 
    width: 70%;
    padding-bottom: 70%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    &:after {

    }

    &.circle-neutral {
      background-image: url(../../public/img/circle-neutral.svg);      
    }    
    &.circle-orange-friz {
      background-image: url(../../public/img/circle-orange-friz.svg);      
    }
    &.circle-orange {
      background-image: url(../../public/img/circle-orange.svg);      
    }
    &.circle-porcino {
      background-image: url(../../public/img/circle-porcino.svg);      
    }
    &.circle-red-friz {
      background-image: url(../../public/img/circle-red-friz.svg);      
    }
    &.circle-red {
      background-image: url(../../public/img/circle-red.svg);      
    }
    &.circle-rose {
      background-image: url(../../public/img/circle-rose.svg);      
    }
    &.circle-rose-friz {
      background-image: url(../../public/img/circle-rose-friz.svg);      
    }
    &.circle-white-friz {
      background-image: url(../../public/img/circle-white-friz.svg);      
    }
    &.circle-white {
      background-image: url(../../public/img/circle-white.svg);      
    }    
  } 
}
.product-card__circle img {
  z-index:1;
}
.product-card__bottle {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
a.product-card__info {
  position: relative;
}
a.product-card__info, a.product-card__info__name, a.product-card__close{
  color: var(--text-color);
  text-decoration: none;
}
.product-card__info {
  @include styleclass('p-3');
}
.product-card__info__name {
  text-align: center;
  @include styleclass('pt-2');
}
.product-card__product__name {
  @include styleclass('pb-1 font-medium');
}
.product-card__product__year {  
  font-size: .85rem;
  @include styleclass('font-normal');
  span {
    @include styleclass('text-sm');
  }
}
.product-card__quantity__in-cart {
    width: 170px;
    height: 170px;
   
    border-radius: 50%!important;
    align-items: center;
    border: 6px solid #fff;
    font-size: 48px;
    font-weight: 500;
    @include styleclass(
    'flex flex-column justify-content-center align-items-center text-green-700'
    );
    .pi-shopping-cart {
      font-size: 30px!important;
    }
}
.product-card__quantity__in-cart {
    width: 170px;
    height: 170px;
   
    border-radius: 50%!important;
    align-items: center;
    border: 6px solid #fff;
    font-size: 48px;
    font-weight: 500;
    @include styleclass(
    'flex flex-column justify-content-center align-items-center text-green-700'
    );
    .pi-shopping-cart {
      font-size: 30px!important;
    }
}
.product-card__quantity {
    height: 223px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
}
.product-card__quantity .p-inputnumber {
    
    align-items: center;
}
.product-card__quantity input {
    background-color:transparent;
    border:none;
    text-align:center;
    font-size: 48px;
    width: 170px;
    height: 170px;
    margin: 0 -31px;
    border-radius: 50%!important;
    align-items: center;
    border: 6px solid var(--beige-light);
    color: #424242;
}
.product-card.-retro{
  .product-card__price {
    justify-content: space-between!important;
    @include styleclass(
    'pl-3 pr-3'
    );
  }

}

@media screen and (max-width: 510px) {
  .product-card__bottle img {
    height: 200px;
  }
  .product-card.-retro{
    .product-card__quantity {
      height: 200px;
    }
    .product-card__quantity__in-cart {
      width: 133px;
      height: 133px;
      .pi {
        font-size: 1.3em!important;
      }
    }
    .product-card__quantity input {      
      width: 133px;
      height: 133px;
      font-size: 40px;      
    }
    .product-card__price {
      align-items: center;
      flex-direction: column;
      height: auto;
      padding: .5rem 0;
      
      > div {
        padding:0!important;
      }
      > div:first-child {
        margin-bottom: 2px;
      }
    }
  }
  .product-card__continue  {
    height:80px;
    > div > button:first-child {
      margin-bottom: 3px;
    }
  }
  .product-card__confirm { 
    height:30px;
  }
  

}
.product-card__quantity input:focus, .product-card__quantity input:hover {
    border-color:#fff!important;
    box-shadow:none!important;
}

.product-card__quantity__button {
  background-color:#424242!important;
  border-color:#424242!important;
  border-radius: 50px!important;
  width: 50px!important;
  height: 50px!important;
}
.product-card__quantity__button:hover {
  background-color:#000!important;
  border-color:#000!important;
  border-radius: 50px!important;
  width: 50px!important;
  height: 50px!important;
}
.product-card__close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 20;
  cursor: pointer
}
.product-card__in-cart {
  position: absolute;
  top: 3px;
  left: 0px;
  @include styleclass('text-sm text-green-700');
}
.product-card__button-add-to-cart {
  background-color: var(--beige-medium)!important;
  border:none!important;
  width: 60px;
  transition: all 0.3s!important;
  @include styleclass('transition-all transition-duration-300 border-noround h-full');
}
.product-card__button-add-to-cart i{
  color: #000;
  transition: all 0.3s!important;
}
.product-card__button-add-to-cart:hover {
  background-color: #000 !important;
  border:none!important;
  transition: all 0.3s!important;   
}
.product-card__button-add-to-cart:hover i{
  color: var(--beige-dark)!important;
  transition: all 0.3s!important;
}
.product-card__button-buy {
  border:none!important;
  @include styleclass('transition-duration-300 surface-800 hover:surface-900 font-bold uppercase text-sm');  
}
.react-card-flip:hover, .react-card-flip.flipped {
    z-index: 15 !important;
}
