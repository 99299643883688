.login-sidebar {
  &.p-sidebar {
    background-color: var(--beige-medium);
  }
}

.filters-sidebar {
  &.p-sidebar-right .p-sidebar{
    width: 480px;
    max-width: 480px;
  }
  .p-sidebar-content {
    padding-top:.5rem!important;
    background-color: var(--beige-light);
  }
  .sidebar-content-container{
    display: flex;
    flex-direction: column;
    height:100%;
    .sidebar-content-content {
        overflow-y: auto;
        flex-grow: 1;

    }
    .sidebar-content-footer {
        flex-shrink: 0;
        padding: 1rem;
        margin: 0 -1rem -1rem -1rem;
        border-top:1px solid #ccc;
        display: flex;
        justify-content:center;
        background-color: var(--beige-dark);
        .p-button:first-child {
          margin-right: 12px;
        }
    }
  }
  .p-button-outlined {
      background-color: transparent!important;
      color: var(--text-color)!important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--beige-dark);
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: transparent;
    border:none;
  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, 
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: var(--beige-medium);
  }
  .p-accordion .p-accordion-content {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--beige-dark);
  }
  .p-listbox {
    background: transparent;
    color: #495057;
    border: none;
    border-radius: 0px;
  }
  .p-listbox .p-listbox-header {
    padding: 0;
    border: none;
    
  
  }
  .p-listbox-item{
    color: var(--text-color)!Important;
    &.p-highlight {
      background: transparent!important;
    }
    .fake-checkbox {
          .p-checkbox .p-checkbox-box.p-highlight {
              border-color: #c2bbb5;
              background: #fff;
              border-radius: 0px;              
          }
      }
    &.p-highlight{
      .fake-checkbox {
          .p-checkbox .p-checkbox-box.p-highlight {
              border-color: var(--onyx);
              background: var(--onyx);
          }
      }
    }
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-disabled):hover {
   
    background: var(--beige-dark)!important;
  }
  
}
.false-radio {
  .p-checkbox .p-checkbox-box {    
    border-radius: 50%!important;
  }
}
.p-inputgroup-addon {
    background: var(--beige-medium-dark);
    color: var(--text-color);
    border-top: 1px solid var(--beige-dark);
    border-left: 1px solid var(--beige-dark);
    border-bottom: 1px solid var(--beige-dark);
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
}
.p-inputtext {
    border: 1px solid var(--beige-dark);
    color: var(--text-color);
}