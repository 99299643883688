.payment-result-wrapper {
 
    background-color: var(--beige-medium);
    background-image: url(../../public/img/r-pattern.svg);
    background-repeat: repeat;
    background-size: 200px;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    flex-direction: column;

}
.payment-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include styleclass('p-4');
  a {
    cursor: pointer;
    text-decoration: none;
  }
}