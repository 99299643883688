@import 'primeflex/primeflex.scss';

.mybutton {
    @include styleclass('bg-blue-500 text-white hover:bg-blue-600 border-round transition-colors transition-duration-150 p-3 border-none');
}

.product-card__product__producer {
  @include styleclass(
    'pb-1 uppercase font-bold'
    );
} 

:root {
  --focus-ring: red;
  --text-color: #212121;
  // #686661
  --link-color: #916d3e;
  --beige-light: #F7F4F0;
  --beige-medium: #eee8e2;
  --beige-medium-dark: #E8E2DC;
  --beige-dark: #d9d4d0; // rgb(231, 228, 220) #E7E4DC
  --beige-dark-alpha: rgba(231, 228, 220, .6);// #E7E4DC
  --black-leather: #312d28; //#312d28
  --grey-dark-warm: #413f40;
  --onyx: #424242;
  --black: #000;
  --honey-bunny:#d7b786;
  --yellow: #fffe00;

  --focus-shadow: 0 0 0px 0.2rem #dfc498;
  --focus-shadow-2: inset 0 0 0 0.15rem #dfc498;
  --focus-border: #d6a529;
}

@mixin for-phone-only {
  @media (max-width: 639px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 640px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 960px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1600px) { @content; }
}

body {
  background-color: var(--grey-dark-warm);

}
h1, h2, h3 {
  color: var(--text-color);
}
p {
  @include styleclass ('line-height-3')
}
a {
  color: var(--link-color);
  text-underline-offset: 2px;
}
/* *:focus {
  box-shadow: var(--focus-shadow)!important;
} */
.App {
  
  font-family:'Sen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  /*letter-spacing: 0.5px;*/

}
.p-component {
  font-family:'Sen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif!important ;
}
.p-button {
  background-color:var(--onyx)!important;
  border-color:var(--onyx)!important;

}
.p-button:hover {
  background-color:var(--black)!important;
  border-color:var(--black)!important;

}
.p-button.btn-honey-bunny {
  background-color:var(--honey-bunny)!important;
  border-color:var(--honey-bunny)!important;
  color: var(--text-color)!important;
}
.p-button.btn-honey-bunny:hover {
  background-color:var(--black)!important;
  border-color:var(--black)!important;
  color: var(--honey-bunny)!important;
}
.mobile-hide {
  @include styleclass ('hidden sm:inline-flex');
}
.bg-transparent {
  background-color: transparent;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.main-layout-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
}
.scroll-top-mobile {
  @include styleclass ('md:hidden');
}
.main-column {

}
.rotate-90 {
      transform: rotate(45deg);
}
.logo-column {
  @include styleclass ('hidden md:flex');
  min-width:100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:flex-start;
  padding-top: 20px;
  img {width: 35px;}
}
.p-progressbar {
    border: 0 none;
    
    margin: 0 .1rem; 
    background: var(--grey-dark-warm);
    border-radius: 0px;
}
.dataview-placeholder {
    width: 100%;
  
    padding: 0.1rem!important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;

  .dw-single-placeholder {
    
    
    padding: 0.1rem !important;
    .dw-sp-content {
      background-color: var(--beige-medium);
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      .p-progress-spinner {
        opacity: .5;
      }
    }
  }
}
.p-datepicker {
  width: 250px;
}
.p-toast {
    opacity: 1;
}
@media screen and (max-width: 960px) {
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: var(--focus-shadow-2);
  }
}
div#CookieDeclarationUserStatusPanel {
    max-width: 80vw;
}

.CookieDeclarationType {   
    max-width: 90vw;
    overflow: auto;
}
.CookieDeclarationTable {
    min-width: 500px;   
}
