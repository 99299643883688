.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-button:focus {
    box-shadow: var(--focus-shadow)!important;
}
.p-inputtext:enabled:focus,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: var(--focus-shadow)!important;
  border-color: var(--focus-border)!important; 
}
.p-inputtext:enabled:hover,
.p-multiselect:not(.p-disabled):hover {
  border-color: var(--focus-border)!important; 
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    box-shadow: var(--focus-shadow-2)!important;
}